.form-wrapper {

  // border: 1px solid #d9d9d9;
  // border-radius: 5px;
  // padding: 5px;
  @include media-breakpoint-down(sm) {
    // padding: 5px;
    margin: 0px;
    // border: 0px solid transparent !important;
  }

  @include media-breakpoint-down(sm) {
    .border-light {
      border: 1px solid #c3c8cd;
    }
  }

  .input-group {
    .react-datepicker-popper {
      @include media-breakpoint-down(sm) {
        transform: translate3d(0px, 6px, 0px) !important;
      }
    }
  }

  // .full-group:first-child{
  //   @include media-breakpoint-down(sm) {
  //     border-bottom: transparent !important;
  //   }
  // }

  .full-group {
    padding: 0px;

    // @include media-breakpoint-down(sm) {
    //   border-bottom: 1px solid #d1d1d1 !important;
    // }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &>.full-input {
      outline: none;
      border: none;
      display: block;
      line-height: 0.9em;
      font-size: 10pt;
      width: 100%;
      padding: 10px;
      color: $color-primary;
      font-weight: 600;
      width: 100%;

      &::-webkit-input-placeholder {
        color: #d0ccd0;
      }

      &:-ms-input-placeholder {
        color: #d0ccd0;
      }

      &::placeholder {
        color: #d0ccd0;
      }
    }

    &>.full-label {
      display: block;
      font-size: 12px;
      color: #4c6085;
      padding: 6px 10px 0;
    }

    // @include media-breakpoint-down(sm) {
    //   border-bottom: transparent !important;
    // }
    .form-row {
      // .form-input-group:nth-child(1) {
      //   @include media-breakpoint-down(sm) {
      //     border-bottom: 0 solid transparent !important;
      //     border-right: 0 solid transparent !important;
      //   }
      // }

      // .form-input-group:nth-child(1) {
      //   @include media-breakpoint-down(sm) {
      //     border-bottom: 1px solid #d1d1d1 !important;
      //   }
      // }

      // .form-input-group:last-child {
      //   @include media-breakpoint-down(sm) {
      //     // border-bottom: 1px solid #d1d1d1 !important;
      //     padding-bottom: 5px !important;
      //   }
      // }

      .form-input-group {
        // border-bottom: 1px solid #d1d1d1 !important;
        // @include media-breakpoint-down(sm) {
        //   border-right: 0 solid transparent !important;
        //   border-bottom: 0 solid transparent !important;
        // }

        &.total_amount_title {
          margin: auto;
          padding-top: 10px;
          text-align: right;

          @include media-breakpoint-down(xs) {
            text-align: center;
          }
        }

        &.amount_title {
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }

        &.cedula_amount_title {
          @include media-breakpoint-down(xs) {
            padding-bottom: 20px;
          }
        }

        &>.full-input {
          outline: none;
          // border: none;
          border: 1px solid #d9d9d9 !important;
          display: block;
          line-height: 0.9em;
          font-size: 10pt;
          width: 100%;
          padding: 10px;
          color: $color-primary;
          font-weight: 600;
          width: 100%;
          line-height: 22px;
          border-radius: 7px;

          &.date-picker {
            width: calc(100% - 46px);
            display: inline;
          }

          &::-webkit-input-placeholder {
            color: #d0ccd0;
          }

          &:-ms-input-placeholder {
            color: #d0ccd0;
          }

          &::placeholder {
            color: #d0ccd0;
          }
        }

        &>.full-input-2 {
          outline: none;
          border: none;
          display: block;
          line-height: 0.9em;
          font-size: 10pt;
          width: 100%;
          padding: 10px;
          color: $color-primary;
          font-weight: 600;
          width: 100%;
          line-height: 22px;
          border-radius: 7px;

          &.date-picker {
            width: calc(100% - 46px);
            display: inline;
          }

          &::-webkit-input-placeholder {
            color: #d0ccd0;
          }

          &:-ms-input-placeholder {
            color: #d0ccd0;
          }

          &::placeholder {
            color: #d0ccd0;
          }
        }

        .full-label {
          display: block;
          // font-size: 12px;
          // font-weight: bold;
          // color: #74767b;
          padding: 6px 0;
          font: normal normal normal 12px/18px Poppins;
          letter-spacing: 0px;
          color: $light-gray;

          &>span.amount_title {
            display: none;

            @include media-breakpoint-down(xs) {
              display: block;
            }
          }
        }

        .full-select {
          border-bottom: 1px solid $silver !important;
          box-shadow: none;
          outline: none;
          border: none;
          display: block;
          line-height: 0.9em;
          font-size: 10pt;
          width: 100%;
          color: $color-secondary;
          font-weight: 600;
          width: 100%;
          background: transparent;

          &::-webkit-input-placeholder {
            color: #d0ccd0;
          }

          &:-ms-input-placeholder {
            color: #d0ccd0;
          }

          &::placeholder {
            color: #d0ccd0;
          }
        }

        .time-picker {
          outline: none;
          border: 1px solid #d9d9d9 !important;
          display: block;
          line-height: 0.9em;
          font-size: 10pt;
          width: 100%;
          padding: 10px;
          color: $color-primary;
          font-weight: 600;
          width: 100%;
          line-height: 22px;
          border-radius: 7px;
        }
      }
    }
  }
}

.form-datepicker {
  width: 110px;
  border: none;
  box-shadow: none;
}

.form-append-blank {
  background: none;
  border: none;
}

.css-1uccc91-singleValue {
  color: #4c6085;
  font-weight: 600;
  font-size: 10pt;
}

.btn-datepicker-handler {
  // div:first-child {
  //   display: inline-block;
  // }

  .react-datepicker__time-container--with-today-button {
    right: -87px !important;
    top: -2px !important;
  }

  .react-datepicker__month {
    margin: 0px !important;
  }
}

.react-tel-input .form-control {
  width: 100% !important;
  padding: 21px 14px 21px 60px !important;
  font-size: 10pt;
  font-weight: 600;
  color: $dark-blue;
}

.pin-inner {
  display: flex;
  justify-content: space-around;
  align-items: center;

  @include media-breakpoint-down(sm) {
    margin-left: -15px;
  }
}

.form-row-mobile {
  flex-wrap: nowrap !important;
  text-align: left !important;
}

.amount-title-mobile {
  text-align: right;
  font-weight: bold;
}

.prepend-form-right {
  position: relative;

  .btn {
    position: absolute;
    bottom: 11px;
    right: 8px;
    z-index: 1;
  }
}

.prepend-input-group {
  position: relative;

  .input-group-prepend {
    position: absolute;
    left: 0;
    height: 100%;

    .input-group-text {
      border: 0;
      background-color: transparent;
      color: $color-secondary;
    }
  }
}

.steps-header {
  background: $lighter-blue;
  padding: 20px 0;

  .nav {
    flex-wrap: nowrap !important;
  }

  div.transaction-selected {
    border-bottom: 5px solid $color-primary;
    border-bottom-width: 2px !important;
    width: 244px;
    opacity: 1;
    margin-top: 10px;
  }

  div.transaction-selected-mobile {
    border-bottom: 5px solid $color-primary !important;
    border-bottom-width: 2px !important;
    border-radius: 5px;
    width: 55px;
    opacity: 1;
    margin-top: 10px;
  }

  div.transaction-selected-birth {
    border-bottom: 5px solid $color-primary;
    border-bottom-width: 2px !important;
    border-radius: 5px;
    width: 200px;
    opacity: 1;
    margin-top: 10px;
  }

  div.transaction-selected-mobile-birth {
    border-bottom: 5px solid $color-primary;
    border-bottom-width: 2px !important;
    border-radius: 5px;
    width: 65px;
    opacity: 1;
    margin-top: 10px;
  }

  div.transaction-under {
    border-bottom: 5px solid $color-primary !important;
    border-bottom-width: 2px !important;
    width: 244px;
    opacity: 0.2;
    margin-top: 10px;
  }

  div.transaction-under-mobile {
    border-bottom: 5px solid $color-primary !important;
    border-bottom-width: 2px !important;
    border-radius: 5px;
    width: 75px;
    opacity: 0.2;
    margin-top: 10px;
  }

  div.transaction-under-bpls {
    border-bottom: 5px solid $color-primary !important;
    border-bottom-width: 4px !important;
    border-radius: 10px;
    width: 110px;
    opacity: 0.2;
    margin-top: 10px;
    transition: 300ms ease-in-out;

    @media (max-width: 768px) {
      width: 70px;
    }

    @media (min-width: 1200px) {
      // width: 244px;
      width: 160px;
    }
  }

  div.transaction-under-bpls-mobile {
    border-bottom: 5px solid $color-primary !important;
    border-bottom-width: 4px !important;
    border-radius: 10px;
    width: 70px;
    opacity: 0.2;
    margin-top: 10px;
    transition: 300ms ease-in-out;

    @media (max-width: 576px) {
      width: 40px;
    }
  }

  div.transaction-selected-bpls {
    transition: 300ms ease-in-out;
    opacity: 1;
  }

  div.transaction-under-birth {
    border-bottom: 5px solid $color-primary !important;
    border-bottom-width: 2px !important;
    border-radius: 5px;
    width: 200px;
    opacity: 0.2;
    margin-top: 10px;
  }

  div.transaction-under-mobile-birth {
    border-bottom: 5px solid $color-primary !important;
    border-bottom-width: 2px !important;
    border-radius: 5px;
    width: 65px;
    opacity: 0.2;
    margin-top: 10px;
  }

  .steps-title {
    color: $light-gray;
    font-size: 12px/30px;
  }

  .steps-title-main {
    color: $color-primary;
    font-size: 20px/30px;
    font-weight: bold;
  }
}

.steps-details {
  margin-top: -50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 12px #00000014;
  // border-radius: 20px 20px 0 0;
  border-radius: 20px 20px;
  padding: 16px;
  border: none;

  .title {
    color: $color-primary;
    font-size: 20px/30px;
    font-weight: 600;
  }

  .text-label {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .title-header {
    color: $title;
    font-size: 18px/27px;
    font-weight: 600;
  }

  .title-header-label {
    color: $title;
    font-size: 16px/25px;
  }

  .title-header-value {
    color: $dark-gray;
    font-size: 16px/25px;
  }

  .title-div {
    color: $light-gray;
    font-size: 12px/18px;
  }

  .title-footer {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .final-description {
    color: $title;
    font-size: 12px;
  }

  .title-tab {
    color: $color-primary;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .text-label {
    color: $light-gray !important;
    font-size: 16px/25px !important;
    font-weight: 600 !important;
  }

  .text-input {
    color: $black !important;
    font-size: 20px/30px !important;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
}

.puv-info {
  color: $black !important;
  background: 0% 0% no-repeat padding-box;
  font-size: 20px/30px !important;
  border-radius: 20px 20px;
  padding: 16px;
  border: none;

}


.steps-details-birth-top {
  margin-top: -50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 12px #00000014;
  border-radius: 20px 20px 0px 0px;
  padding: 16px;
  border: none;

  .title {
    color: $color-primary;
    font-size: 15px;
  }

  .text-label {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .title-header {
    color: $title;
    font-size: 18px/27px;
    font-weight: 600;
  }

  .title-header-label {
    color: $title;
    font-size: 16px/25px;
  }

  .title-header-value {
    color: $dark-gray;
    font-size: 16px/25px;
  }

  .title-div {
    color: $light-gray;
    font-size: 12px/18px;
  }

  .title-footer {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .final-description {
    color: $title;
    font-size: 12px;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
}

.steps-details-birth-bottom {
  margin: 30px -15px -47px -15px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 0px 12px #00000014;
  border-radius: 10px 10px 0px 0px;
  padding: 16px;
  border: none;

  .title {
    color: $color-primary;
    font-size: 15px;
  }

  .text-label {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .title-header {
    color: $title;
    font-size: 18px/27px;
    font-weight: 600;
  }

  .title-header-label {
    color: $title;
    font-size: 16px/25px;
  }

  .title-header-value {
    color: $dark-gray;
    font-size: 16px/25px;
  }

  .title-div {
    color: $light-gray;
    font-size: 12px/18px;
  }

  .title-footer {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .final-description {
    color: $title;
    font-size: 12px;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
}

.final-div {
  margin-top: 30px;

  .final-label {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .final-label-div {
    color: $title;
    font-size: 16px;
  }

  .final-amount {
    color: $dark-gray;
    font-size: 16px/25px;
  }

  .final-description {
    color: $title;
    font-size: 12px;
  }
}

.steps-right {
  margin-top: -15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 0px 20px 0px 0px;
  opacity: 1;
  padding: 15px;

  .title {
    color: $title;
    font-size: 15px;
    font-weight: bold;
  }

  .data-text {
    color: $title;
    font-size: 12px;
  }

  .title-value-bold {
    color: $title;
    font-size: 12px;
    font-weight: bold;
  }

  .title-value {
    color: $title;
    font-size: 12px;
  }

  .span-total {
    color: $black;
    font-size: 16px/18px;
    font-weight: 600;
  }

  .span-total-value {
    color: $title;
    font-size: 12px/18px;
  }

  .title-label {
    color: $title;
    font-size: 10px;
  }

  .amount-label {
    color: $black;
    font-size: 16px/25px;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
    width: 768px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}

.steps-right-corp {
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 0px 20px 0px 0px;
  opacity: 1;
  margin-bottom: -16px;

  .title {
    color: $title;
    font-size: 15px;
    font-weight: bold;
  }

  .data-text {
    color: $title;
    font-size: 12px;
  }

  .title-label {
    color: $title;
    font-size: 10px;
    font-weight: bold;
  }

  .title-value-bold {
    color: $title;
    font-size: 12px;
    font-weight: bold;
  }

  .title-value {
    color: $title;
    font-size: 12px;
  }

  .title-header {
    color: $title;
    font-size: 18px/27px;
    font-weight: 600;
  }

  .title-header-label {
    color: $title;
    font-size: 16px/25px;
  }

  .title-header-value {
    color: $dark-gray;
    font-size: 16px/25px;
  }

  .title-div {
    color: $light-gray;
    font-size: 12px/18px;
  }

  .title-footer {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .final-description {
    color: $title;
    font-size: 12px;
  }

  .span-total {
    color: $black;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .amount-label {
    color: $black;
    font-size: 16px/25px;
  }

  .span-total {
    color: $black;
    font-size: 16px/18px;
    font-weight: 600;
  }

  .span-total-value {
    color: $title;
    font-size: 12px/18px;
  }

  .title-label {
    color: $title;
    font-size: 10px;
  }

  .span-amount {
    color: $black;
    font-size: 16px/18px;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}

.steps-right-mobile {
  margin-top: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 0px 12px #00000014;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  padding: 20px;

  .title {
    color: $title;
    font-size: 13px;
    font-weight: bold;
  }

  .data-text {
    color: $title;
    font-size: 12px;
  }

  .title-label {
    color: $title;
    font-size: 10px;
    font-weight: bold;
  }

  .title-value-bold {
    color: $title;
    font-size: 12px;
    font-weight: bold;
  }

  .title-value {
    color: $title;
    font-size: 12px;
  }

  .label-message {
    color: $black;
    font-size: 12px/18px;
  }

  .amount-label {
    color: $black;
    font-size: 16px/25px;
  }

  .span-total {
    color: $black;
    font-size: 16px/18px;
    font-weight: 600;
  }

  .span-title {
    color: $black;
    font-size: 14px/21px;
  }

  .span-value {
    color: $black;
    font-size: 16px/25px;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 12px;
  }
}

.summary-title {
  color: $dark-black;
  font-size: 30px/46px;
  font-weight: bold;
}

.summary-value {
  color: $black;
  font-size: 20px/30px;
}

.main-div {
  height: 100%;

  .content-div {
    height: 100%;
  }
}

.summary-details {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 12px #00000014;
  border-radius: 20px;
  padding: 16px;
  border: none;
  margin-left: 20px;
  margin-right: 5px;

  .title {
    color: $color-primary;
    font-size: 15px;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}

.summary-details-2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 12px #00000014;
  border-radius: 20px;
  padding: 16px;
  border: none;

  .title {
    color: $color-primary;
    font-size: 15px;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}

.summary-details-mobile {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 12px #00000014;
  border-radius: 20px;
  padding: 16px;
  border: none;
  margin-left: 20px;
  margin-right: 15px;

  .title {
    color: $color-primary;
    font-size: 15px;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 12px;
  }
}

.summary-right {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 12px #00000014;
  border-radius: 20px;
  padding: 16px;
  border: none;

  .right-bottom {
    margin-bottom: 288px;
  }

  .right-bottom-birth {
    margin-bottom: 1750px;
  }

  .right-bottom-death {
    margin-bottom: 695px;
  }

  .right-bottom-corporation {
    margin-bottom: 380px;
  }

  .title {
    color: $title;
    font-size: 15px;
    font-weight: bold;
  }

  .data-text {
    color: $title;
    font-size: 12px;
  }

  .title-label {
    color: $title;
    font-size: 10px;
    font-weight: bold;
  }

  .title-value-bold {
    color: $title;
    font-size: 12px;
    font-weight: bold;
  }

  .title-value {
    color: $title;
    font-size: 12px;
  }

  div.amount-div {
    width: 100%;
    text-align: right;

    .amount-value {
      color: $title;
      font-size: 12px;
    }
  }

  .title-header {
    color: $title;
    font-size: 18px;
    font-weight: 600;
  }

  .title-header-label {
    color: $title;
    font-size: 16px/25px;
  }

  .title-header-value {
    color: $dark-gray;
    font-size: 16px/25px;
  }

  .title-div {
    color: $light-gray;
    font-size: 12px/18px;
  }

  .title-footer {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .span-total {
    color: $black;
    font-size: 16px/18px;
    font-weight: 600;
  }

  .span-total-value {
    color: $title;
    font-size: 12px/18px;
  }

  .title-label {
    color: $title;
    font-size: 10px;
  }

  .amount-label {
    color: $black;
    font-size: 16px/25px;
  }

  .span-amount {
    color: $black;
    font-size: 16px/18px;
  }

  .text-label {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
    width: 440px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0px;
    width: 440px;
  }
}

.summary-right-mobile {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 12px #00000014;
  border-radius: 20px;
  padding: 16px;
  border: none;
  margin-left: 20px;
  margin-right: 15px;
  margin-top: 10px;

  .title {
    color: $title;
    font-size: 15px;
    font-weight: bold;
  }

  .data-text {
    color: $title;
    font-size: 12px;
  }

  .title-label {
    color: $title;
    font-size: 12px/18px;
    font-weight: bold;
  }

  .title-value-bold {
    color: $title;
    font-size: 12px;
    font-weight: bold;
  }

  .title-value {
    color: $title;
    font-size: 12px;
  }

  div.amount-div {
    width: 100%;
    text-align: right !important;

    .amount-value {
      color: $black;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .title-header {
    color: $title;
    font-size: 18px/27px;
    font-weight: 600;
  }

  .title-header-label {
    color: $title;
    font-size: 16px/25px;
  }

  .title-header-value {
    color: $dark-gray;
    font-size: 16px/25px;
  }

  .title-div {
    color: $light-gray;
    font-size: 12px/18px;
  }

  .title-footer {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .span-total {
    color: $black;
    font-size: 16px/18px;
    font-weight: 600;
  }

  .span-total-value {
    color: $title;
    font-size: 12px/18px;
  }

  .title-label {
    color: $title;
    font-size: 10px;
  }

  .amount-label {
    color: $black;
    font-size: 16px/25px;
  }

  .span-amount {
    color: $black;
    font-size: 16px/18px;
  }

  .span-total {
    color: $black !important;
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .span-total-value {
    color: $title;
    font-size: 12px/18px;
  }

  .title-label {
    color: $title;
    font-size: 10px;
  }

  .text-label {
    color: $title;
    font-size: 16px/25px;
    font-weight: 600;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 12px;
  }
}

.summary-edit-button {
  height: 25px;
  width: 25px;
}

.summary-submit {
  border: none;
  width: 100%;

  .summary-left {
    margin-left: 35px;
  }

  .title {
    color: $color-primary;
    font-size: 15px;
  }

  .summary-form-banner {
    background-color: #cce4fc;
  }

  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}

.summary-submit-mobile {
  border: none;
  width: 100%;

  .title {
    color: $color-primary;
    font-size: 15px;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}

.summary-submit-rpt {
  border: none;
  width: 100%;
  margin-top: 100px;

  .title {
    color: $color-primary;
    font-size: 15px;
  }

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 12px;
  }
}

.soa-div {
  font-size: small;
}

.left-div {
  width: 850px;
  height: 100%;
}

.right-div {
  width: 400px;
  height: 100%;
}

.span-bg-blue {
  background: $lighter-blue;
  padding: 10px;

  .span-title {
    color: $light-gray;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .span-input {
    color: $black;
    font-size: 20px/30px;
    word-break: break-word;
  }
}

.span-bg-alabaster {
  background: $alabaster;
  padding: 10px;

  .span-title {
    color: $light-gray;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .span-input {
    color: $black;
    font-size: 20px/30px;
    word-break: break-word;
  }
}

div.form-wrapper-alternate-bg {
  .row:nth-child(odd) {
    background-color: $lighter-blue;
  }

  // @include media-breakpoint-up(sm) {
  //   .row:nth-child(odd) {
  //     background-color: $lighter-blue;
  //   }
  // }
}

.light-blue-bg {
  background-color: $lighter-blue;
}

.span-title2 {
  color: #9fa2a7;
}

.span-input2 {
  color: #000000;
}

.border-green {
  border: 1px solid #0a8a45;
}

.span-bg {
  padding: 10px;

  .span-title {
    color: $light-gray;
    font-size: 16px/25px;
    font-weight: 600;
  }

  .span-input {
    color: $black;
    font-size: 20px/30px;
    word-break: break-word;
  }
}

.profile-photo {
  border: 3px solid $color-primary;
}

.citizen-title {
  color: $light-gray;
  font: 15px;
  font-weight: bold;
}

.smooth-scroll-to-top {
  scroll-behavior: "smooth";
}

input.google-map-search-input {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 300px;
  height: 41px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  outline: none;
  text-overflow: ellipsis;
  position: absolute;
  top: 65px;
  left: 10px;

  @include media-breakpoint-down(sm) {
    width: 195px
  }
}

.animated-spinner {
  background: url("../../img/animated-spinner.svg") center center no-repeat;
}

.overflow-table {
  @include media-breakpoint-down(md) {
    display: block;
    width: 100%;
    overflow-x: auto;

    .small-table {
      width: 180%;
      overflow-x: scroll;
    }

    .medium-table {
      width: 280%;
      overflow-x: scroll;
    }
  }
}

// fix for react-big-calendar `+(#) more` not showing
.rbc-event {
  height: 35px !important;

  @include media-breakpoint-down(sm) {
    overflow: scroll;
    height: 30px !important;
    font-size: 10px;
  }
}

div.rbc-event.rbc-selected {
  background-color: #f7d904 !important;
  color: #000 !important;
}

.rbc-button-link.rbc-show-more {
  @include media-breakpoint-down(sm) {
    font-size: 9px;
  }
}