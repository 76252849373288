.form-label {
  font-weight: 500;
  font-size: 14px;
  color: $gray-500;
  line-height: 24px;
}

.title-module {
  color: $dark-black;
  font-size: 30px/46px;
  font-weight: 600;
}

.description-label {
  color: $sepia;
}

.label-modal {
  font-weight: 500;
  font-size: 25px;
  color: $color-primary;
  line-height: 24px;
}

.label-header {
  font-weight: 500;
  font-size: 20px;
  color: $color-primary;
  line-height: 24px;
}

.password-label {
  font-size: 12px !important;
  color: #9fa2a7 !important;
  font-weight: 600;
}

.password-background {
  color: #4c6085;
  background-color: #ecf4fa;
}

.sub-text {
  color: #606774;
  font-size: 14px;
}

.step-css {
  color: #4c6085;
  font-size: 20px;
}

.label-authenticate {
  font-size: 12px;
  font-weight: bold;
  color: $color-primary;
}
